import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Avatar, Popover } from "antd";
import { useAuth } from "../../authentication";
import { useHistory } from "react-router-dom";
import { getProfileFrontpage } from "../../api/index";

const UserInfo = () => {
  const { userSignOut } = useAuth();
  const history = useHistory();

  const [pageState, setPageState] = useState({
    userName: "",
    userPictureLink: "/assets/images/c4c-avatar-backoffice.png",
  });

  useEffect(() => {
    getProfileFrontpage({}).then((reply) => {
      setPageState({
        userName: reply.data.userName,
        userPictureLink: reply.data.userPictureLink,
      });
    });
  }, []);

  const onLogoutClick = () => {
    userSignOut(() => {
      history.push('/');
    });
  }

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li><Link to="/administration/profile">My Account</Link></li>
      <li onClick={onLogoutClick}>Logout
      </li>
    </ul>
  );

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions} trigger="click">
      <Avatar 
          src={pageState.userPictureLink} 
          className="gx-avatar gx-pointer"
          alt={pageState.userName}
        />
    </Popover>
  );
};

export default UserInfo;
