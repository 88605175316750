import {
    handlePOSTRequest,
    handleDOWNLOADRequest,
    handleTemplateDownloadRequest,
    handleTemplateExportRequest,
    handleSimplePostRequest,
} from "./api-core";

/** Edit this part */
export const getToken = (data) => handlePOSTRequest("getToken", data);

/* Login for Admin and Distributor : BEGIN */
export const getAdminLogin = () => handlePOSTRequest("getAdminLogin");
export const postAdminLogin = (data) => handlePOSTRequest("postAdminLogin", data);
export const postAdminLogout = () => handlePOSTRequest("postAdminLogout");
export const initializeAdmin = () => handlePOSTRequest("initializeAdmin");
export const getAllAdminPermits = () => handlePOSTRequest("getAllAdminPermits");
/* Login for Admin and Distributor : END */

export const getAdminListPage = (data) => handlePOSTRequest("getAdminListPage",data);
export const getAdminDetailPage = (data) => handlePOSTRequest("getAdminDetailPage",data);
export const getAdminFileListPage = (data) => handlePOSTRequest("getAdminFileListPage",data);
export const getAdminFileUploadPage = (data) => handlePOSTRequest("getAdminFileUploadPage",data,true);
export const getAdminFileUploadProgress = (data) => handlePOSTRequest("getAdminFileUploadProgress",data);
export const getAdminFileUploadMessagesPage = (data) => handlePOSTRequest("getAdminFileUploadMessagesPage",data);
export const downloadAdminFile = (data) => handleDOWNLOADRequest("backoff/administration/admin/file-download-file","getAdminFileDownloadName",data);
export const getAdminFilter = (data) => handlePOSTRequest("getAdminFilter",data);
export const downloadAdminTemplateFile = (data) => handleDOWNLOADRequest("backoff/administration/admin/template-file-download-file","getAdminTemplateFileDownloadName",data);
export const getRoleListPage = (data) => handlePOSTRequest("getRoleListPage",data);
export const getRoleDetailPage = (data) => handlePOSTRequest("getRoleDetailPage",data);
export const getRoleFileListPage = (data) => handlePOSTRequest("getRoleFileListPage",data);
export const getRoleFileUploadPage = (data) => handlePOSTRequest("getRoleFileUploadPage",data,true);
export const getRoleFileUploadProgress = (data) => handlePOSTRequest("getRoleFileUploadProgress",data);
export const getRoleFileUploadMessagesPage = (data) => handlePOSTRequest("getRoleFileUploadMessagesPage",data);
export const downloadRoleFile = (data) => handleDOWNLOADRequest("backoff/administration/role/file-download-file","getRoleFileDownloadName",data);
export const getRoleFilter = (data) => handlePOSTRequest("getRoleFilter",data);
export const downloadRoleTemplateFile = (data) => handleDOWNLOADRequest("backoff/administration/role/template-file-download-file","getRoleTemplateFileDownloadName",data);
export const getProfileView = (data) => handlePOSTRequest("getProfileView",data);
export const getProfileFrontpage = (data) => handlePOSTRequest("getProfileFrontpage",data);
export const getProfileChangePassword = (data) => handlePOSTRequest("getProfileChangePassword",data);
export const getPermitActionShowPermitActionDocumentation = (data) => handlePOSTRequest("getPermitActionShowPermitActionDocumentation",data);
export const getDownloadFileListPage = (data) => handlePOSTRequest("getDownloadFileListPage",data);
export const getDownloadFileStatusPage = (data) => handlePOSTRequest("getDownloadFileStatusPage",data);
export const downloadDownloadFileResultFile = (data) => handleDOWNLOADRequest("backoff/administration/download-file/result-file-download-file","getDownloadFileResultFileDownloadName",data);


export const getGeoProvinceListPage = (data) => handlePOSTRequest("getGeoProvinceListPage",data);
export const getGeoProvinceEditPage = (data) => handlePOSTRequest("getGeoProvinceEditPage",data);
export const getGeoProvinceFilterNeo = (data) => handlePOSTRequest("getGeoProvinceFilterNeo",data);
export const getGeoKabkotaListPage = (data) => handlePOSTRequest("getGeoKabkotaListPage",data);
export const getGeoKabkotaEditPage = (data) => handlePOSTRequest("getGeoKabkotaEditPage",data);
export const getGeoKabkotaFilterNeo = (data) => handlePOSTRequest("getGeoKabkotaFilterNeo",data);
export const getGeoKecamatanListPage = (data) => handlePOSTRequest("getGeoKecamatanListPage",data);
export const getGeoKecamatanEditPage = (data) => handlePOSTRequest("getGeoKecamatanEditPage",data);
export const getGeoKecamatanFilterNeo = (data) => handlePOSTRequest("getGeoKecamatanFilterNeo",data);
export const getGeoKelurahanListPage = (data) => handlePOSTRequest("getGeoKelurahanListPage",data);
export const getGeoKelurahanEditPage = (data) => handlePOSTRequest("getGeoKelurahanEditPage",data);
export const getGeoKelurahanFilterNeo = (data) => handlePOSTRequest("getGeoKelurahanFilterNeo",data);
export const getTerritoryListPage = (data) => handlePOSTRequest("getTerritoryListPage",data);
export const getTerritoryEditPage = (data) => handlePOSTRequest("getTerritoryEditPage",data);
export const getTerritoryFilterNeo = (data) => handlePOSTRequest("getTerritoryFilterNeo",data);
export const getAreaListPage = (data) => handlePOSTRequest("getAreaListPage",data);
export const getAreaEditPage = (data) => handlePOSTRequest("getAreaEditPage",data);
export const getAreaFilterNeo = (data) => handlePOSTRequest("getAreaFilterNeo",data);
export const getBranchListPage = (data) => handlePOSTRequest("getBranchListPage",data);
export const getBranchEditPage = (data) => handlePOSTRequest("getBranchEditPage",data);
export const getBranchFilterNeo = (data) => handlePOSTRequest("getBranchFilterNeo",data);
export const getBranchPostalListPage = (data) => handlePOSTRequest("getBranchPostalListPage",data);
export const getBranchPostalEditPage = (data) => handlePOSTRequest("getBranchPostalEditPage",data);
export const getBranchPostalFilterNeo = (data) => handlePOSTRequest("getBranchPostalFilterNeo",data);
export const getVehicleCategoryListPage = (data) => handlePOSTRequest("getVehicleCategoryListPage",data);
export const getVehicleCategoryEditPage = (data) => handlePOSTRequest("getVehicleCategoryEditPage",data);
export const getVehicleCategoryFilterNeo = (data) => handlePOSTRequest("getVehicleCategoryFilterNeo",data);
export const getVehicleGroupListPage = (data) => handlePOSTRequest("getVehicleGroupListPage",data);
export const getVehicleGroupEditPage = (data) => handlePOSTRequest("getVehicleGroupEditPage",data);
export const getVehicleGroupFilterNeo = (data) => handlePOSTRequest("getVehicleGroupFilterNeo",data);
export const getVehicleBrandListPage = (data) => handlePOSTRequest("getVehicleBrandListPage",data);
export const getVehicleBrandEditPage = (data) => handlePOSTRequest("getVehicleBrandEditPage",data);
export const getVehicleBrandFilterNeo = (data) => handlePOSTRequest("getVehicleBrandFilterNeo",data);
export const getVehicleTypeListPage = (data) => handlePOSTRequest("getVehicleTypeListPage",data);
export const getVehicleTypeEditPage = (data) => handlePOSTRequest("getVehicleTypeEditPage",data);
export const getVehicleTypeFilterNeo = (data) => handlePOSTRequest("getVehicleTypeFilterNeo",data);
export const getVehicleModelListPage = (data) => handlePOSTRequest("getVehicleModelListPage",data);
export const getVehicleModelEditPage = (data) => handlePOSTRequest("getVehicleModelEditPage",data);
export const getVehicleModelFilterNeo = (data) => handlePOSTRequest("getVehicleModelFilterNeo",data);
export const getInsuranceTypeListPage = (data) => handlePOSTRequest("getInsuranceTypeListPage",data);
export const getInsuranceTypeEditPage = (data) => handlePOSTRequest("getInsuranceTypeEditPage",data);
export const getInsuranceTypeFilterNeo = (data) => handlePOSTRequest("getInsuranceTypeFilterNeo",data);
export const getInsuranceTypeTenureListPage = (data) => handlePOSTRequest("getInsuranceTypeTenureListPage",data);
export const getInsuranceTypeTenureEditPage = (data) => handlePOSTRequest("getInsuranceTypeTenureEditPage",data);
export const getInsuranceTypeTenureFilterNeo = (data) => handlePOSTRequest("getInsuranceTypeTenureFilterNeo",data);
export const getVehicleCategoryTenureListPage = (data) => handlePOSTRequest("getVehicleCategoryTenureListPage",data);
export const getVehicleCategoryTenureEditPage = (data) => handlePOSTRequest("getVehicleCategoryTenureEditPage",data);
export const getVehicleCategoryTenureFilterNeo = (data) => handlePOSTRequest("getVehicleCategoryTenureFilterNeo",data);
export const getVehicleValuationListPage = (data) => handlePOSTRequest("getVehicleValuationListPage",data);
export const getVehicleValuationExcelFileBuild = (data) => handlePOSTRequest("getVehicleValuationExcelFileBuild",data);
export const getVehicleCategoryCostListPage = (data) => handlePOSTRequest("getVehicleCategoryCostListPage",data);
export const getVehicleCategoryCostExcelFileBuild = (data) => handlePOSTRequest("getVehicleCategoryCostExcelFileBuild",data);
export const getAreaCostInsuranceListPage = (data) => handlePOSTRequest("getAreaCostInsuranceListPage",data);
export const getAreaCostInsuranceExcelFileBuild = (data) => handlePOSTRequest("getAreaCostInsuranceExcelFileBuild",data);
export const getAreaCostLoanAmountListPage = (data) => handlePOSTRequest("getAreaCostLoanAmountListPage",data);
export const getAreaCostLoanAmountExcelFileBuild = (data) => handlePOSTRequest("getAreaCostLoanAmountExcelFileBuild",data);
export const getAreaCostTenureListPage = (data) => handlePOSTRequest("getAreaCostTenureListPage",data);
export const getAreaCostTenureExcelFileBuild = (data) => handlePOSTRequest("getAreaCostTenureExcelFileBuild",data);
export const getAreaCostAnfpListPage = (data) => handlePOSTRequest("getAreaCostAnfpListPage",data);
export const getAreaCostAnfpExcelFileBuild = (data) => handlePOSTRequest("getAreaCostAnfpExcelFileBuild",data);
export const getPartnerListPage = (data) => handlePOSTRequest("getPartnerListPage",data);
export const getPartnerEditPage = (data) => handlePOSTRequest("getPartnerEditPage",data);
export const getPartnerFilterNeo = (data) => handlePOSTRequest("getPartnerFilterNeo",data);
export const getPartnerPageListPage = (data) => handlePOSTRequest("getPartnerPageListPage",data);
export const getPartnerPageEditPage = (data) => handlePOSTRequest("getPartnerPageEditPage",data);
export const getPartnerPageFilterNeo = (data) => handlePOSTRequest("getPartnerPageFilterNeo",data);
export const getPartnerInputListPage = (data) => handlePOSTRequest("getPartnerInputListPage",data);
export const getPartnerInputEditPage = (data) => handlePOSTRequest("getPartnerInputEditPage",data);
export const getPartnerInputFilterNeo = (data) => handlePOSTRequest("getPartnerInputFilterNeo",data);
export const getPartnerEmbedRuleListPage = (data) => handlePOSTRequest("getPartnerEmbedRuleListPage",data);
export const getPartnerEmbedRuleEditPage = (data) => handlePOSTRequest("getPartnerEmbedRuleEditPage",data);
export const getPartnerInputChoicePage = (data) => handlePOSTRequest("getPartnerInputChoicePage",data);
export const getPartnerChoiceListPage = (data) => handlePOSTRequest("getPartnerChoiceListPage",data);
export const getPartnerChoiceEditPage = (data) => handlePOSTRequest("getPartnerChoiceEditPage",data);
export const getPartnerChoiceFilterNeo = (data) => handlePOSTRequest("getPartnerChoiceFilterNeo",data);
export const getPartnerChoiceExcelFileBuild = (data) => handlePOSTRequest("getPartnerChoiceExcelFileBuild",data);
export const getPartnerCostListPage = (data) => handlePOSTRequest("getPartnerCostListPage",data);
export const getPartnerCostExcelFileBuild = (data) => handlePOSTRequest("getPartnerCostExcelFileBuild",data);
export const getPartnerTenureCostListPage = (data) => handlePOSTRequest("getPartnerTenureCostListPage",data);
export const getPartnerTenureCostExcelFileBuild = (data) => handlePOSTRequest("getPartnerTenureCostExcelFileBuild",data);
export const getOccupationListPage = (data) => handlePOSTRequest("getOccupationListPage",data);
export const getOccupationEditPage = (data) => handlePOSTRequest("getOccupationEditPage",data);
export const getOccupationFilterNeo = (data) => handlePOSTRequest("getOccupationFilterNeo",data);
export const getAreaFileFileUploadPage = (data) => handlePOSTRequest("getAreaFileFileUploadPage",data,true);
export const getAreaFileFileUploadStatus = (data) => handlePOSTRequest("getAreaFileFileUploadStatus",data);
export const getAreaFileFileUploadProgress = (data) => handlePOSTRequest("getAreaFileFileUploadProgress",data);
export const downloadAreaFileUploadedFile = (data) => handleDOWNLOADRequest("backoff/master/area-file/file-download-file","getAreaFileUploadedFileDownloadName",data);
export const getAreaFileUploadedFileList = (data) => handlePOSTRequest("getAreaFileUploadedFileList",data);
export const getAreaFileUploadedFileMessage = (data) => handlePOSTRequest("getAreaFileUploadedFileMessage",data);
export const downloadAreaFileTemplateFile = (data) => handleDOWNLOADRequest("backoff/master/area-file/template-file-download-file","getAreaFileTemplateFileDownloadName",data);
export const getBranchFileFileUploadPage = (data) => handlePOSTRequest("getBranchFileFileUploadPage",data,true);
export const getBranchFileFileUploadStatus = (data) => handlePOSTRequest("getBranchFileFileUploadStatus",data);
export const getBranchFileFileUploadProgress = (data) => handlePOSTRequest("getBranchFileFileUploadProgress",data);
export const downloadBranchFileUploadedFile = (data) => handleDOWNLOADRequest("backoff/master/branch-file/file-download-file","getBranchFileUploadedFileDownloadName",data);
export const getBranchFileUploadedFileList = (data) => handlePOSTRequest("getBranchFileUploadedFileList",data);
export const getBranchFileUploadedFileMessage = (data) => handlePOSTRequest("getBranchFileUploadedFileMessage",data);
export const downloadBranchFileTemplateFile = (data) => handleDOWNLOADRequest("backoff/master/branch-file/template-file-download-file","getBranchFileTemplateFileDownloadName",data);
export const getGeographyFileFileUploadPage = (data) => handlePOSTRequest("getGeographyFileFileUploadPage",data,true);
export const getGeographyFileFileUploadStatus = (data) => handlePOSTRequest("getGeographyFileFileUploadStatus",data);
export const getGeographyFileFileUploadProgress = (data) => handlePOSTRequest("getGeographyFileFileUploadProgress",data);
export const downloadGeographyFileUploadedFile = (data) => handleDOWNLOADRequest("backoff/master/geography-file/file-download-file","getGeographyFileUploadedFileDownloadName",data);
export const getGeographyFileUploadedFileList = (data) => handlePOSTRequest("getGeographyFileUploadedFileList",data);
export const getGeographyFileUploadedFileMessage = (data) => handlePOSTRequest("getGeographyFileUploadedFileMessage",data);
export const downloadGeographyFileTemplateFile = (data) => handleDOWNLOADRequest("backoff/master/geography-file/template-file-download-file","getGeographyFileTemplateFileDownloadName",data);
export const getVehicleFileFileUploadPage = (data) => handlePOSTRequest("getVehicleFileFileUploadPage",data,true);
export const getVehicleFileFileUploadStatus = (data) => handlePOSTRequest("getVehicleFileFileUploadStatus",data);
export const getVehicleFileFileUploadProgress = (data) => handlePOSTRequest("getVehicleFileFileUploadProgress",data);
export const downloadVehicleFileUploadedFile = (data) => handleDOWNLOADRequest("backoff/master/vehicle-file/file-download-file","getVehicleFileUploadedFileDownloadName",data);
export const getVehicleFileUploadedFileList = (data) => handlePOSTRequest("getVehicleFileUploadedFileList",data);
export const getVehicleFileUploadedFileMessage = (data) => handlePOSTRequest("getVehicleFileUploadedFileMessage",data);
export const downloadVehicleFileTemplateFile = (data) => handleDOWNLOADRequest("backoff/master/vehicle-file/template-file-download-file","getVehicleFileTemplateFileDownloadName",data);
export const getVehicleFileImageFileUploadPage = (data) => handlePOSTRequest("getVehicleFileImageFileUploadPage",data,true);
export const getAreaCostFileFileUploadPage = (data) => handlePOSTRequest("getAreaCostFileFileUploadPage",data,true);
export const getAreaCostFileFileUploadStatus = (data) => handlePOSTRequest("getAreaCostFileFileUploadStatus",data);
export const getAreaCostFileFileUploadProgress = (data) => handlePOSTRequest("getAreaCostFileFileUploadProgress",data);
export const downloadAreaCostFileUploadedFile = (data) => handleDOWNLOADRequest("backoff/master/area-cost-file/file-download-file","getAreaCostFileUploadedFileDownloadName",data);
export const getAreaCostFileUploadedFileList = (data) => handlePOSTRequest("getAreaCostFileUploadedFileList",data);
export const getAreaCostFileUploadedFileMessage = (data) => handlePOSTRequest("getAreaCostFileUploadedFileMessage",data);
export const downloadAreaCostFileTemplateFile = (data) => handleDOWNLOADRequest("backoff/master/area-cost-file/template-file-download-file","getAreaCostFileTemplateFileDownloadName",data);
export const getPartnerFileFileUploadPage = (data) => handlePOSTRequest("getPartnerFileFileUploadPage",data,true);
export const getPartnerFileFileUploadStatus = (data) => handlePOSTRequest("getPartnerFileFileUploadStatus",data);
export const getPartnerFileFileUploadProgress = (data) => handlePOSTRequest("getPartnerFileFileUploadProgress",data);
export const downloadPartnerFileUploadedFile = (data) => handleDOWNLOADRequest("backoff/master/partner-file/file-download-file","getPartnerFileUploadedFileDownloadName",data);
export const getPartnerFileUploadedFileList = (data) => handlePOSTRequest("getPartnerFileUploadedFileList",data);
export const getPartnerFileUploadedFileMessage = (data) => handlePOSTRequest("getPartnerFileUploadedFileMessage",data);
export const downloadPartnerFileTemplateFile = (data) => handleDOWNLOADRequest("backoff/master/partner-file/template-file-download-file","getPartnerFileTemplateFileDownloadName",data);


export const getCardTestimonyListPage = (data) => handlePOSTRequest("getCardTestimonyListPage",data);
export const getCardTestimonySpecificRecord = (data) => handlePOSTRequest("getCardTestimonySpecificRecord",data);
export const getCardTestimonyEditPage = (data) => handlePOSTRequest("getCardTestimonyEditPage",data);
export const getCardTestimonyFilterNeo = (data) => handlePOSTRequest("getCardTestimonyFilterNeo",data);
export const getCardInformationListPage = (data) => handlePOSTRequest("getCardInformationListPage",data);
export const getCardInformationSpecificRecord = (data) => handlePOSTRequest("getCardInformationSpecificRecord",data);
export const getCardInformationEditPage = (data) => handlePOSTRequest("getCardInformationEditPage",data);
export const getCardInformationFilterNeo = (data) => handlePOSTRequest("getCardInformationFilterNeo",data);
export const getCardBenefitListPage = (data) => handlePOSTRequest("getCardBenefitListPage",data);
export const getCardBenefitSpecificRecord = (data) => handlePOSTRequest("getCardBenefitSpecificRecord",data);
export const getCardBenefitEditPage = (data) => handlePOSTRequest("getCardBenefitEditPage",data);
export const getCardBenefitFilterNeo = (data) => handlePOSTRequest("getCardBenefitFilterNeo",data);
export const getStaticContentListPage = (data) => handlePOSTRequest("getStaticContentListPage",data);
export const getStaticContentSpecificRecord = (data) => handlePOSTRequest("getStaticContentSpecificRecord",data);
export const getStaticContentEditPage = (data) => handlePOSTRequest("getStaticContentEditPage",data);
export const getStaticContentFilterNeo = (data) => handlePOSTRequest("getStaticContentFilterNeo",data);
export const getQuestionListPage = (data) => handlePOSTRequest("getQuestionListPage",data);
export const getQuestionSpecificRecord = (data) => handlePOSTRequest("getQuestionSpecificRecord",data);
export const getQuestionEditPage = (data) => handlePOSTRequest("getQuestionEditPage",data);
export const getQuestionFilterNeo = (data) => handlePOSTRequest("getQuestionFilterNeo",data);
export const getArticleListPage = (data) => handlePOSTRequest("getArticleListPage",data);
export const getArticleSpecificRecord = (data) => handlePOSTRequest("getArticleSpecificRecord",data);
export const getArticleEditPage = (data) => handlePOSTRequest("getArticleEditPage",data);
export const getArticleFilterNeo = (data) => handlePOSTRequest("getArticleFilterNeo",data);
export const getCardTestimonyFileUploadPage = (data) => handlePOSTRequest("getCardTestimonyFileUploadPage",data,true);
export const getCardBenefitFileUploadPage = (data) => handlePOSTRequest("getCardBenefitFileUploadPage",data,true);
export const getCardInformationFileUploadPage = (data) => handlePOSTRequest("getCardInformationFileUploadPage",data,true);
export const getArticleFileUploadPage = (data) => handlePOSTRequest("getArticleFileUploadPage",data,true);


export const getNumberBasedCalculate = (data) => handlePOSTRequest("getNumberBasedCalculate",data);
export const getAreaCarCalculate = (data) => handlePOSTRequest("getAreaCarCalculate",data);
export const getAreaCarInputControl = (data) => handlePOSTRequest("getAreaCarInputControl",data);


export const getOrderList = (data) => handlePOSTRequest("getOrderList",data);
export const getOrderSpecific = (data) => handlePOSTRequest("getOrderSpecific",data);
export const getOrderCalculation = (data) => handlePOSTRequest("getOrderCalculation",data);
export const getOrderVerification = (data) => handlePOSTRequest("getOrderVerification",data);
export const getOrderLeadEngine = (data) => handlePOSTRequest("getOrderLeadEngine",data);
export const getOrderResendLeadEngine = (data) => handlePOSTRequest("getOrderResendLeadEngine",data);
export const getOrderSimpleExcelFileBuild = (data) => handlePOSTRequest("getOrderSimpleExcelFileBuild",data);
export const getOrderDetailExcelFileBuild = (data) => handlePOSTRequest("getOrderDetailExcelFileBuild",data);
export const getOrderAllExcelFileBuild = (data) => handlePOSTRequest("getOrderAllExcelFileBuild",data);
export const getReportReport = (data) => handlePOSTRequest("getReportReport",data);
export const getReportDashboard = (data) => handlePOSTRequest("getReportDashboard",data);
export const getReportDashboardPartner = (data) => handlePOSTRequest("getReportDashboardPartner",data);

// ADR-Partnership
export const getAdrPartnerUploadLeadList = (data) => handlePOSTRequest('getAdrPartnerUploadLeadList', data);
export const getAdrPartnerUploadLeadTemplate = () => handleTemplateDownloadRequest('backoff/adr-partner/upload-lead/download-template');
export const getAdrPartnerUploadLeadSubmit = (data) => handlePOSTRequest('getAdrPartnerUploadLeadSubmit', data, true);
export const getAdrPartnerExportLeadTemplate = (id) => handleTemplateExportRequest(`backoff/adr-partner/upload-lead/export-lead-errors/${id}`);
export const getAdrPartnerReloadLeadSubmit = (id) => handleSimplePostRequest(`backoff/adr-partner/upload-lead/reload-leads/${id}`);


