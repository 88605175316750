import React, { useState, useEffect }  from "react";
import { Link } from "react-router-dom";
import {Avatar, Popover} from "antd";
import {useAuth} from "../../authentication";
import {useHistory} from "react-router-dom";
import { getProfileFrontpage } from "../../api/index";

const UserProfile = () => {
  const {userSignOut} = useAuth();
  const history = useHistory();

  const [pageState, setPageState] = useState({
    userName: "",
    userPictureLink: "/assets/images/c4c-avatar-backoffice.png",
  });

  useEffect(() => {
    getProfileFrontpage({}).then((reply) => {
      setPageState({
        userName: reply.data.userName,
        userPictureLink: reply.data.userPictureLink,
      });
    });
  }, []);

  const onLogoutClick = () => {
    userSignOut(() => {
      history.push('/');
    });
  }

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li className="popover__item"><Link to="/administration/profile">My Account</Link></li>
      <li onClick={onLogoutClick}>Logout
      </li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar 
          src={pageState.userPictureLink} 
          className="gx-size-40 gx-pointer gx-mr-3" 
          alt={pageState.userName}
        />
        <span className="gx-avatar-name">{pageState.userName}<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
      </Popover>
    </div>
  )
};

export default UserProfile;
