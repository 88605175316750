import MainService from "./base";
import { ClientStorage } from "ClientStorage";
import { env } from "../util/env";
import moment from 'moment'

const clientStorage = new ClientStorage();

const appTokenHeader = env("REACT_APP_TOKEN_HEADER");
const convertToFormData = (formData, data, previousKey) => {
  if (data instanceof Object) {
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (key === "file") {
        formData.append(key, value);
      } else {
        if (value instanceof Object && !Array.isArray(value)) {
          return convertToFormData(formData, value, key);
        }
        if (previousKey) {
          key = `${previousKey}[${key}]`;
        }
        if (Array.isArray(value)) {
          value.forEach((val) => {
            formData.append(`${key}[]`, val);
          });
        } else {
          formData.append(key, value);
        }
      }
    });
  }
};


const handleGeneralError = (error) => console.log("General Error", error);
export const handleDOWNLOADRequest = async (apiFile, apiName, body) => {
  const formData = new FormData();
  convertToFormData(formData, body);
  let actualBody = formData;

  let token = clientStorage.get("token");

  Promise.all([
    fetch(env("REACT_APP_BASE_URL") + "/" + apiFile, {
      method: 'POST',
      headers: new Headers({
        [appTokenHeader]: token
      }),
      body: actualBody
    }).then((response) => {
      return response.blob();
    }),
    handlePOSTRequest(apiName, body).then((response) => {
      return response.data.filename;
    })
  ]).then(([blob, filename]) => {
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
  });
};

export const handlePOSTRequest = async (api, body, asFormData = false) => {
  const formData = new FormData();
  let actualBody = { ...body };

  if (asFormData) {
    convertToFormData(formData, body);
    actualBody = formData;
  }

  const {
    result: {
      body: { data, error, code },
    },
  } = await MainService(api)
    .doRequest({
      body: actualBody,
      hooks: {
        before({ payload, next }) {
          const newPayload = { ...payload };
          if (asFormData) delete newPayload.headers["Content-Type"];
          next(newPayload);
        },
      },
    })
    .then((result) => result)
    .catch((errorGeneral) => {
      handleGeneralError(errorGeneral);
      return {
        result: {
          body: { data: null, error: null },
        },
        errorJS: errorGeneral,
      };
    });

  // if (error) console.log(error);

  return {
    code,
    data,
    error,
  };
};

export const handleGETRequest = async (api, { ...body }) => {
  const {
    result: {
      body: { data, error, code },
    },
  } = await MainService(api)
    .doRequest({ body: { ...body } })
    .then((result) => result)
    .catch((errorGeneral) => {
      handleGeneralError(errorGeneral);
      return {
        result: {
          body: { data: null, error: null },
        },
        errorJS: errorGeneral,
      };
    });

  return {
    code,
    data,
    error,
  };
};

export const handleTemplateDownloadRequest = async (apiFile, body = {}) => {
  let token = clientStorage.get('token');
  let actualBody = { ...body }

  Promise.all([
    fetch(env('REACT_APP_BASE_URL') + '/' + apiFile, {
      method: 'POST',
      headers: new Headers({
        [appTokenHeader]: token
      }),
      body: actualBody,
    }).then((response) => response.blob()),
  ]).then(([blob]) => {
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = url;
    a.download = `template-upload-${moment().valueOf()}.xlsx`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  });
};

export const handleTemplateExportRequest = async (apiFile, body = {}) => {
  let token = clientStorage.get('token');
  let actualBody = { ...body }

  Promise.all([
    fetch(env('REACT_APP_BASE_URL') + '/' + apiFile, {
      method: 'POST',
      headers: new Headers({
        [appTokenHeader]: token
      }),
      body: actualBody,
    }).then((response) => response.blob()),
  ]).then(([blob]) => {
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = url;
    a.download = `template-export-${moment().valueOf()}.xlsx`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  });
};

export const handleSimplePostRequest = async (apiUrl, body = {}) => {
  let token = clientStorage.get('token');
  let actualBody = { ...body }

  try {
    const {
      code,
      data,
      error,
    } = await fetch(env('REACT_APP_BASE_URL') + '/' + apiUrl, {
      method: 'POST',
      headers: new Headers({
        [appTokenHeader]: token
      }),
      body: actualBody,
    }).then(res => res.json())

    return {
      code,
      data,
      error,
    }
  } catch (error) {
    handleGeneralError(error)
    
    return {
      result: {
        body: {
          data: null,
          error: null
        },
      },
      errorJS: error,
    }
  }
}