import { useEffect, useState } from 'react';
import {
  getAdminLogin,
  postAdminLogin,
  postAdminLogout
} from "../../../api/index";

export const useProvideAuth = () => {
  const [authUser, setAuthUser] = useState(null);
  const [error, setError] = useState('');
  const [isLoadingUser, setLoadingUser] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const fetchStart = () => {
    setError("");
    setLoading(true);
  };

  const fetchSuccess = () => {
    setError("");
    setLoading(false);
  };

  const fetchError = (error) => {
    setLoading(false);
    setError(error);
  };

  const userLogin = (user, callbackFun) => {
    fetchStart();
    postAdminLogin({ username: user.email, password: user.password })
      .then((adminLogin) => {
        if (adminLogin.data.status) {
          fetchSuccess();
          localStorage.setItem("token", adminLogin.data.token);
          getAuthUser();
          if (callbackFun) callbackFun();
        } else {
          fetchError(adminLogin.data.error);
        }
      })
      .catch((error) => {
        fetchError(error.message);
      });
  };

  const userSignup = (user, callbackFun) => {
    fetchStart();

  };

  const sendPasswordResetEmail = (email, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const confirmPasswordReset = (code, password, callbackFun) => {
    fetchStart();

    setTimeout(() => {
      fetchSuccess();
      if (callbackFun) callbackFun();
    }, 300);
  };

  const renderSocialMediaLogin = () => null;

  const userSignOut = (callbackFun) => {
    fetchStart();
    
    postAdminLogout()
      .then((adminLogout) => {
        if (adminLogout.data.status) {
          fetchSuccess();
          localStorage.removeItem("token");
          setAuthUser(false);
          if (callbackFun) callbackFun();
        } else {
          fetchError(adminLogout.data.error);
        }
      })
      .catch((error) => {
        fetchError(error.message);
      });
  };

  const getAuthUser = () => {
    fetchStart();

    getAdminLogin()
      .then((adminLogin) => {
        if (adminLogin.data.status) {
          fetchSuccess();
          setAuthUser(adminLogin.data.user);
        } else {
          fetchError(adminLogin.data.error);
        }
      })
      .catch(function (error) {
        fetchError(error.message);
      });
  }

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.

  useEffect(() => {
    getAdminLogin()
      .then((adminLogin) => {
        if (adminLogin.data.status) {
          setAuthUser(adminLogin.data.user);
        }
        setLoadingUser(false);
      })
      .catch(function (error) {
        localStorage.removeItem('token');
        setLoadingUser(false);
      });
  }, []);

  // Return the user object and auth methods
  return {
    isLoadingUser,
    isLoading,
    authUser,
    error,
    setError,
    setAuthUser,
    getAuthUser,
    userLogin,
    userSignup,
    userSignOut,
    renderSocialMediaLogin,
    sendPasswordResetEmail,
    confirmPasswordReset,
  };

};