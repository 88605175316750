import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const App = (props) => {
  let match = props.match;

  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route path={`${match.url}dashboard/:timeStep/:timeFinish/:timeStart`}
          render={(routeProps) => {
            const ContentAsync = asyncComponent(() => import('../business-components/pages/Dashboard'));
            return <ContentAsync
              {...routeProps}
              permit={props.permit}
            />;
          }}
        />
        <Route path={`${match.url}dashboard/:timeStep/:timeFinish`}
          render={(routeProps) => {
            const ContentAsync = asyncComponent(() => import('../business-components/pages/Dashboard'));
            return <ContentAsync
              {...routeProps}
              permit={props.permit}
            />;
          }}
        />
        <Route path={`${match.url}dashboard/:timeStep`}
          render={(routeProps) => {
            const ContentAsync = asyncComponent(() => import('../business-components/pages/Dashboard'));
            return <ContentAsync
              {...routeProps}
              permit={props.permit}
            />;
          }} />
        <Route path={`${match.url}dashboard`} render={(routeProps) => {
          const ContentAsync = asyncComponent(() => import('../business-components/pages/Dashboard'));
          return <ContentAsync
            {...routeProps}
            permit={props.permit}
          />;
        }} />
        <Route
          path={`${match.url}content`}
          render={(routeProps) => {
            const ContentAsync = asyncComponent(() => import('./Content'));
            return <ContentAsync
              {...routeProps}
              permit={props.permit}
            />;
          }}
        />
        <Route
          path={`${match.url}master`}
          render={(routeProps) => {
            const ContentAsync = asyncComponent(() => import('./Master'));
            return <ContentAsync
              {...routeProps}
              permit={props.permit}
            />;
          }}
        />
        <Route
          path={`${match.url}administration`}
          render={(routeProps) => {
            const ContentAsync = asyncComponent(() => import('./Administration'));
            return <ContentAsync
              {...routeProps}
              permit={props.permit}
            />;
          }}
        />
        <Route
          path={`${match.url}calculator`}
          render={(routeProps) => {
            const ContentAsync = asyncComponent(() => import('./Calculator'));
            return <ContentAsync
              {...routeProps}
              permit={props.permit}
            />;
          }}
        />
        <Route
          path={`${match.url}report`}
          render={(routeProps) => {
            const ContentAsync = asyncComponent(() => import('./Report'));
            return <ContentAsync
              {...routeProps}
              permit={props.permit}
            />;
          }}
        />
        <Route
          path={`${match.url}partner-order`}
          render={(routeProps) => {
            const ContentAsync = asyncComponent(() => import('./Order'));
            return <ContentAsync
              {...routeProps}
              permit={props.permit}
            />;
          }}
        />
      </Switch>
    </div>
  )
}

export default App;
