import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import { getAllAdminPermits } from "../../api/index";

import BelowHeader from "../Topbar/BelowHeader/index";
import Topbar from "../Topbar/index";
import { footerText } from "../../util/config";
import App from "../../routes/index";

import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
import { useRouteMatch } from "react-router-dom";
import { updateWindowWidth } from "../../appRedux/actions";
import AppSidebar from "./AppSidebar";

const { Content, Footer } = Layout;

const getContainerClass = (navStyle) => {
  switch (navStyle) {
    case NAV_STYLE_DARK_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_DEFAULT_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_BELOW_HEADER:
      return "gx-container-wrap";
    case NAV_STYLE_ABOVE_HEADER:
      return "gx-container-wrap";
    default:
      return '';
  }
};

const getNavStyles = (navStyle) => {
  switch (navStyle) {
    case NAV_STYLE_DEFAULT_HORIZONTAL:
      return <HorizontalDefault />;
    case NAV_STYLE_DARK_HORIZONTAL:
      return <HorizontalDark />;
    case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
      return <InsideHeader />;
    case NAV_STYLE_ABOVE_HEADER:
      return <AboveHeader />;
    case NAV_STYLE_BELOW_HEADER:
      return <BelowHeader />;
    case NAV_STYLE_FIXED:
      return <Topbar />;
    case NAV_STYLE_DRAWER:
      return <Topbar />;
    case NAV_STYLE_MINI_SIDEBAR:
      return <Topbar />;
    case NAV_STYLE_NO_HEADER_MINI_SIDEBAR:
      return <NoHeaderNotification />;
    case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
      return <NoHeaderNotification />;
    default:
      return null;
  }
};

const MainApp = () => {
  const { navStyle } = useSelector(({ settings }) => settings);
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const [permitState, setPermitState] = useState({
    getPermit: false,
    permits: [],
    permitPointer: {},
    adminType:"Admin"
  })

  useEffect(() => {
    refreshPermit();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(updateWindowWidth(window.innerWidth));
    })
  }, [dispatch]);

  const refreshPermit = () => {
    getAllAdminPermits({}).then((reply) => {
      let permitPointer = {};
      let newState = {
        getPermit: reply.data.success,
        permits: reply.data.permits
      };
      let n = newState.permits.length;
      let i = 0;
      for (i = 0; i < n; i++) {
        let permit = newState.permits[i];
        permitPointer[permit.menuLink] = newState.permits[i].actions;
      }
      newState.permitPointer = permitPointer;
      newState.adminType = reply.data.adminType;
      setPermitState(newState);
    });
  }

  const checkPermitLinkPrefix = (linkPrefix) => {
    let i = 0;
    let permitFound = false;
    let linkPrefixLength = linkPrefix.length;
    while (permitState.permits[i] && !permitFound) {
      let permit = permitState.permits[i];
      if (permit.menuLink.substr(0, linkPrefixLength) === linkPrefix) {
        permitFound = true;
      }
      i++;
    }
    return permitFound;
  };

  const checkSpecific = (link, action) => {
    let permitFound = false;
    if (permitState.permitPointer[link] != undefined) {
      permitFound = permitState.permitPointer[link].includes(action);
    }
    return permitFound;
  }

  const checkAdminType = (adminType) =>{
    return (permitState.adminType==adminType);
  }

  return (
    <Layout className="gx-app-layout">
      {permitState.getPermit && (
        <AppSidebar
          navStyle={navStyle}
          permit={{
            state: permitState,
            setState: setPermitState,
            checkPermitLinkPrefix: checkPermitLinkPrefix,
            refreshPermit: refreshPermit,
            checkSpecific: checkSpecific,
            checkAdminType: checkAdminType
          }}
        />
      )}
      <Layout>
        {getNavStyles(navStyle)}
        <Content className={`gx-layout-content ${getContainerClass(navStyle)} `}>
          {permitState.getPermit && (
            <App
              match={match}
              permit={{
                state: permitState,
                setState: setPermitState,
                checkPermitLinkPrefix: checkPermitLinkPrefix,
                refreshPermit: refreshPermit,
                checkSpecific: checkSpecific,
                checkAdminType: checkAdminType
              }}
            />
          )}
          <Footer>
            <div className="gx-layout-footer-content">
              {footerText}
            </div>
          </Footer>
        </Content>
      </Layout>
    </Layout>
  )
};
export default MainApp;

