export function checkLinkPrefix(linkPrefix, el) {
  const currentUrl = window.location.pathname
  const desiredPath = linkPrefix

  if(el === "item") {
    if (currentUrl.includes(desiredPath)) {
      return "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
    } else {
      return "ant-menu-item ant-menu-item-only-child"
    }
  } else if (el === "subMenu") {
    if (currentUrl.includes(desiredPath)) {
      return "ant-menu-submenu ant-menu-submenu-inline ant-menu-submenu-open ant-menu-submenu-selected"
    } else {
      return "ant-menu-submenu ant-menu-submenu-inline"
    }
  } else {
    return
  }
}

export function checkLinkDouble(link) {
  const currentUrl = window.location.pathname
  const parts = currentUrl.split('/');

  const pathSliced = '/' + parts[1] +'/'+ parts[2]; 
  
  if(link === pathSliced) {
    if (pathSliced.includes(link)) {
      return "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
    } else {
      return "ant-menu-item ant-menu-item-only-child"
    }
  } else {
    return
  }
}

export function checkLinkFull(linkFull, linkInclude, el) {
  const currentUrl = window.location.pathname
  if(el === "item") {
    if (linkFull[0] === currentUrl || linkFull[1] === currentUrl || linkFull[2] === currentUrl || currentUrl.includes(linkInclude)) {
      return "ant-menu-item ant-menu-item-only-child ant-menu-item-selected"
    } else {
      return "ant-menu-item ant-menu-item-only-child"
    }
  } else if (el === "subMenu") {
    if (linkFull[0] === currentUrl || linkFull[1] === currentUrl || linkFull[2] === currentUrl || currentUrl.includes(linkInclude)) {
      return "ant-menu-submenu ant-menu-submenu-inline ant-menu-submenu-open ant-menu-submenu-selected"
    } else {
      return "ant-menu-submenu ant-menu-submenu-inline"
    }
  } else {
    return
  }
}

export function linkClassMatch(linkFull, linkInclude, el) {
  const currentUrl = window.location.pathname;

  const isSelected = linkFull.some(url => url === currentUrl || linkInclude.some(partOfUrl => currentUrl.includes(partOfUrl)));

  if (el === "item") {
    return isSelected ? "ant-menu-item ant-menu-item-only-child ant-menu-item-selected" : "ant-menu-item ant-menu-item-only-child";
  } else if (el === "subMenu") {
    return isSelected ? "ant-menu-submenu ant-menu-submenu-inline ant-menu-submenu-open ant-menu-submenu-selected" : "ant-menu-submenu ant-menu-submenu-inline";
  }
}