import React from "react";
import { Route, Switch } from "react-router-dom";

import SidebarContentMain from "./SidebarContentMain";

const SidebarSwitcher = (props) => {
  return (
    <React.Fragment>
      {props.permit.state && (
        <Switch>
          <Route path="/">
            <SidebarContentMain
              themeType={props.themeType}
              defaultOpenKeys={props.defaultOpenKeys}
              navStyleSubMenuClass={props.navStyleSubMenuClass}
              selectedKeys={props.selectedKeys}
              permit={props.permit}
            />
          </Route>
        </Switch>
      )}
    </React.Fragment>
  );
}

export default SidebarSwitcher;
