import React from "react";
import SidebarLogo from "./SidebarLogo";
import UserProfileDesktop from "../UserProfile/UserProfileDesktop";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR
} from "../../constants/ThemeSetting";
import {useSelector} from "react-redux";
import SidebarSwitcher from "./SidebarSwitcher";

const SidebarContent = ({sidebarCollapsed, setSidebarCollapsed, permit}) => {
  const {navStyle, themeType} = useSelector(({settings}) => settings);
  const pathname = useSelector(({common}) => common.pathname);

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  const navStyleSubMenuClass=getNavStyleSubMenuClass(navStyle);

  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed}/>
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfileDesktop/>
        </div>
        <SidebarSwitcher
          themeType={themeType} 
          defaultOpenKeys={defaultOpenKeys} 
          navStyleSubMenuClass={navStyleSubMenuClass}
          selectedKeys={selectedKeys}
          permit={permit}
        />
      </div>
    </>
  );
};

export default React.memo(SidebarContent);

