import CustomScrollbars from "util/CustomScrollbars";
import { Link } from "react-router-dom";

import { Menu } from "antd";
import { THEME_TYPE_LITE } from "../../constants/ThemeSetting";
import { checkLinkDouble, checkLinkPrefix, linkClassMatch } from "./checkLinkPrefix";

import { useEffect } from "react";

const SubMenu = Menu.SubMenu;
const Item = Menu.Item;
const MenuItemGroup = Menu.ItemGroup;

const SidebarContentMain = ({
  themeType,
  defaultOpenKeys,
  navStyleSubMenuClass,
  selectedKeys,
  permit,
}) => {

  useEffect(() => {
    checkLinkPrefix("/content/static-content")
  }, [])

  const getPermitMenuName = (strLink, strPermit) => {
    const permits = permit?.state?.permits
    if (permits) {
      const findPermit = permits?.find(item => item.menuLink === strLink && item.actions.includes(strPermit))
      return findPermit.menuName
    }
    return '-'
  }

  return (
    <CustomScrollbars className="gx-layout-sider-scrollbar">
      <Menu
        defaultOpenKeys={[defaultOpenKeys]}
        selectedKeys={[selectedKeys]}
        theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
        mode="inline"
      >
        <Item key="dashboard">
          <Link to="/dashboard">
            <i className="icon icon-dasbhoard" />
            <span>Dashboard</span>
          </Link>
        </Item>
        {permit.checkSpecific('/partner-order', 'view') && (
          <MenuItemGroup key="partner-order" className="gx-menu-group">
            <SubMenu
              key="partner-order"
              popupClassName={navStyleSubMenuClass}
              title={
                <span>
                  <i className="icon icon-select" />
                  <span>{getPermitMenuName('/partner-order', 'view')}</span>
                </span>
              }>
              {permit.checkSpecific('/partner-order/upload-pengajuan', 'view') && (
                <Item key="partner-order-upload-pengajuan">
                  <Link to="/partner-order/upload-pengajuan">
                    <span>{getPermitMenuName('/partner-order/upload-pengajuan', 'view')}</span>
                  </Link>
                </Item>
              )}
            </SubMenu>
          </MenuItemGroup>
        )}
        {permit.checkPermitLinkPrefix("/administration") && (
          <MenuItemGroup key="admin" className="gx-menu-group">
            <SubMenu
              key="admin"
              popupClassName={navStyleSubMenuClass}
              className={checkLinkPrefix(["/administration"], "subMenu")}
              title={
                <span>
                  <i className="icon icon-auth-screen" />
                  <span>Admin & Role</span>
                </span>
              }>
              <Item
                key="administration/admin-file"
                className={checkLinkDouble("/administration/admin-file") || checkLinkDouble("/administration/admin-file-upload")}
              >
                <Link to="/administration/admin-file">
                  <span>Admin File</span>
                </Link>
              </Item>

              <Item
                key="administration/admin"
                className={checkLinkDouble("/administration/admin")}
              >
                <Link to="/administration/admin">
                  <span>Admin List</span>
                </Link>
              </Item>

              <Item
                key="administration/role-file"
                className={checkLinkDouble("/administration/role-file") || checkLinkDouble("/administration/role-file-upload")}
              >
                <Link to="/administration/role-file">
                  <span>Role File</span>
                </Link>
              </Item>

              <Item
                key="administration/role"
                className={checkLinkDouble("/administration/role")}
              >
                <Link to="/administration/role">
                  <span>Role List</span>
                </Link>
              </Item>
            </SubMenu>
          </MenuItemGroup>
        )}

        {permit.checkPermitLinkPrefix("/report") && (
          <MenuItemGroup key="report" className="gx-menu-group">
            <SubMenu
              key="report"
              popupClassName={navStyleSubMenuClass}
              className={checkLinkPrefix("/report", "subMenu")}
              title={
                <span>
                  <i className="icon icon-select" />
                  <span>Reports</span>
                </span>
              }>
              <Item
                key="report/order"
                className={checkLinkPrefix("/report/order", "item")}
              >
                <Link to="/report/order">
                  <span>Pengajuan Pinjaman</span>
                </Link>
              </Item>
            </SubMenu>
          </MenuItemGroup>
        )}

        {permit.checkPermitLinkPrefix("/content") && (
          <MenuItemGroup key="content" className="gx-menu-group">
            <SubMenu
              key="content"
              popupClassName={navStyleSubMenuClass}
              className={checkLinkPrefix("/content", "subMenu")}
              title={
                <span>
                  <i className="icon icon-card" />
                  <span>Konten CMS</span>
                </span>
              }>

              {permit.checkSpecific("/content/static", "view") && (
                <Item
                  key="content/static-content"
                  className={checkLinkPrefix("/content/static-content", "item")}
                >
                  <Link to="/content/static-content">
                    <span>Konten Statis</span>
                  </Link>
                </Item>
              )}
              {permit.checkSpecific("/content/static", "view") && (
                <Item
                  key="content/card-benefit"
                  className={checkLinkPrefix("/content/card-benefit", "item")}
                >
                  <Link to="/content/card-benefit">
                    <span>Benefit</span>
                  </Link>
                </Item>
              )}
              {permit.checkSpecific("/content/information", "view") && (
                <Item
                  key="content/card-information"
                  className={checkLinkPrefix("/content/card-information", "item")}
                >
                  <Link to="/content/card-information">
                    <span>Informasi</span>
                  </Link>
                </Item>
              )}
              {permit.checkSpecific("/content/testimony", "view") && (
                <Item
                  key="content/card-testimony"
                  className={checkLinkPrefix("/content/card-testimony", "item")}
                >
                  <Link to="/content/card-testimony">
                    <span>Testimoni</span>
                  </Link>
                </Item>
              )}
              {permit.checkSpecific("/content/faq", "view") && (
                <Item
                  key="content/question"
                  className={checkLinkPrefix("/content/question", "item")}
                >
                  <Link to="/content/question">
                    <span>FAQ</span>
                  </Link>
                </Item>
              )}
              {permit.checkSpecific("/content/article", "view") && (
                <Item
                  key="content/article"
                  className={checkLinkPrefix("/content/article", "item")}
                >
                  <Link to="/content/article">
                    <span>Artikel</span>
                  </Link>
                </Item>
              )}
            </SubMenu>
          </MenuItemGroup>
        )}

        {permit.checkPermitLinkPrefix("/master/pricing") && (
          <MenuItemGroup key="pricing" className="gx-menu-group">
            <SubMenu
              key="pricing"
              popupClassName={navStyleSubMenuClass}
              className={checkLinkPrefix("/master/pricing", "subMenu")}
              title={
                <span>
                  <i className="icon icon-pricing-table" />
                  <span>Daftar Harga</span>
                </span>
              }>
              <Item
                key="master/pricing/area-cost-file-list"
                className={checkLinkPrefix("/master/pricing/area-cost-file", "item")}
              >
                <Link to="/master/pricing/area-cost-file-list">
                  <span>File Harga</span>
                </Link>
              </Item>
              {permit.checkSpecific("/master/pricing/nilai-kendaraan", "view") && (
                <Item
                  key="master/pricing/vehicle-valuation"
                  className={checkLinkPrefix("/master/pricing/vehicle-valuation", "item")}
                >
                  <Link to="/master/pricing/vehicle-valuation">
                    <span>Nilai Kendaraan</span>
                  </Link>
                </Item>
              )}
              {permit.checkSpecific("/master/pricing/batas-kategori", "view") && (
                <Item
                  key="master/pricing/vehicle-category-cost"
                  className={checkLinkPrefix("/master/pricing/vehicle-category-cost", "item")}
                >
                  <Link to="/master/pricing/vehicle-category-cost">
                    <span>Batas Kategori</span>
                  </Link>
                </Item>
              )}
              {permit.checkSpecific("/master/pricing/premi-unit", "view") && (
                <Item
                  key="master/pricing/area-cost-insurance"
                  className={checkLinkPrefix("/master/pricing/area-cost-insurance", "item")}
                >
                  <Link to="/master/pricing/area-cost-insurance">
                    <span>Premi Unit</span>
                  </Link>
                </Item>
              )}
              {permit.checkSpecific("/master/pricing/fidusia", "view") && (
                <Item
                  key="master/pricing/area-cost-loan-amount"
                  className={checkLinkPrefix("/master/pricing/area-cost-loan-amount", "item")}
                >
                  <Link to="/master/pricing/area-cost-loan-amount">
                    <span>Fidusia</span>
                  </Link>
                </Item>
              )}
              {permit.checkSpecific("/master/pricing/admin-pa", "view") && (
                <Item
                  key="master/pricing/area-cost-tenure"
                  className={checkLinkPrefix("/master/pricing/area-cost-tenure", "item")}
                >
                  <Link to="/master/pricing/area-cost-tenure">
                    <span>Admin & PA</span>
                  </Link>
                </Item>
              )}
              {permit.checkSpecific("/master/pricing/afcp", "view") && (
                <Item
                  key="master/pricing/area-cost-anfp"
                  className={checkLinkPrefix("/master/pricing/area-cost-anfp", "item")}
                >
                  <Link to="/master/pricing/area-cost-anfp">
                    <span>AFCP</span>
                  </Link>
                </Item>
              )}
              {permit.checkSpecific("/master/pricing/insurance-tenor", "view") && (
                <Item
                  key="master/pricing/insurance-type"
                >
                  <Link to="/master/pricing/insurance-type">
                    <span>Tipe Asuransi</span>
                  </Link>
                </Item>
              )}
              {permit.checkSpecific("/master/pricing/insurance-tenor", "view") && (
                <Item
                  key="master/pricing/vehicle-category-tenure"
                >
                  <Link to="/master/pricing/vehicle-category-tenure">
                    <span>Kendaraan-Tenor</span>
                  </Link>
                </Item>
              )}
              {permit.checkSpecific("/master/pricing/insurance-tenor", "view") && (
                <Item
                  key="master/pricing/insurance-type-tenure"
                >
                  <Link to="/master/pricing/insurance-type-tenure">
                    <span>Asuransi-Tenor</span>
                  </Link>
                </Item>
              )}
            </SubMenu>
          </MenuItemGroup>
        )}

        {permit.checkPermitLinkPrefix("/master/vehicle") && (
          <MenuItemGroup key="Kendaraan" className="gx-menu-group">
            <SubMenu
              key="Kendaraan"
              popupClassName={navStyleSubMenuClass}
              className={checkLinkPrefix("/master/vehicle", "subMenu")}
              title={
                <span>
                  <i className="icon icon-callout" />
                  <span>Kendaraan</span>
                </span>
              }>
              <Item
                key="master/vehicle/vehicle-file-list"
                className={checkLinkPrefix("/master/vehicle/vehicle-file", "item")}
              >
                <Link to="/master/vehicle/vehicle-file-list">
                  <span>File Kendaraan</span>
                </Link>
              </Item>
              <Item key="master/vehicle/vehicle-model">
                <Link to="/master/vehicle/vehicle-model">
                  <span>Model</span>
                </Link>
              </Item>
              <Item key="master/vehicle/vehicle-category">
                <Link to="/master/vehicle/vehicle-category">
                  <span>Kategori</span>
                </Link>
              </Item>
              <Item key="master/vehicle/vehicle-group">
                <Link to="/master/vehicle/vehicle-group">
                  <span>Grup</span>
                </Link>
              </Item>
              <Item key="master/vehicle/vehicle-type">
                <Link to="/master/vehicle/vehicle-type">
                  <span>Tipe</span>
                </Link>
              </Item>
              <Item key="master/vehicle/vehicle-brand">
                <Link to="/master/vehicle/vehicle-brand">
                  <span>Merek</span>
                </Link>
              </Item>
            </SubMenu>
          </MenuItemGroup>
        )}

        {permit.checkPermitLinkPrefix("/master/branch") && (
          <MenuItemGroup key="areaCabang" className="gx-menu-group">
            <SubMenu
              key="areaCabang"
              popupClassName={navStyleSubMenuClass}
              className={checkLinkPrefix("/master/branch", "subMenu")}
              title={
                <span>
                  <i className="icon icon-map-drawing" />
                  <span>Area dan Cabang</span>
                </span>
              }>
              <Item
                key="master/branch/area-file-list"
                className={checkLinkPrefix("/master/branch/area-file", "item")}
              >
                <Link to="/master/branch/area-file-list">
                  <span>File Area</span>
                </Link>
              </Item>

              <Item
                key="master/branch/branch-file-list"
                className={checkLinkPrefix("/master/branch/branch-file", "item")}
              >
                <Link to="/master/branch/branch-file-list">
                  <span>File Cabang</span>
                </Link>
              </Item>

              <Item key="master/branch/territory">
                <Link to="/master/branch/territory">
                  <span>Wilayah</span>
                </Link>
              </Item>

              <Item key="master/branch/area">
                <Link to="/master/branch/area">
                  <span>Area</span>
                </Link>
              </Item>

              <Item key="master/branch/branch">
                <Link to="/master/branch/branch">
                  <span>Cabang</span>
                </Link>
              </Item>

              <Item key="master/branch/branch-postal">
                <Link to="/master/branch/branch-postal">
                  <span>Kode Pos Cabang</span>
                </Link>
              </Item>
            </SubMenu>
          </MenuItemGroup>
        )}

        {permit.checkPermitLinkPrefix("/master/geography") && (
          <MenuItemGroup key="region" className="gx-menu-group">
            <SubMenu
              key="region"
              popupClassName={navStyleSubMenuClass}
              className={checkLinkPrefix("/master/geography", "subMenu")}
              title={
                <span>
                  <i className="icon icon-location" />
                  <span>Wilayah</span>
                </span>
              }>
              <Item
                key="master/geography/geography-file-list"
                className={checkLinkPrefix("/master/geography/geography-file", "item")}
              >
                <Link to="/master/geography/geography-file-list">
                  <span>File Geografi</span>
                </Link>
              </Item>

              <Item key="master/geography/geo-province">
                <Link to="/master/geography/geo-province">
                  <span>Provinsi</span>
                </Link>
              </Item>

              <Item key="master/geography/geo-kabkota">
                <Link to="/master/geography/geo-kabkota">
                  <span>Kabupaten</span>
                </Link>
              </Item>

              <Item key="master/geography/geo-kecamatan">
                <Link to="/master/geography/geo-kecamatan">
                  <span>Kecamatan</span>
                </Link>
              </Item>

              <Item key="master/geography/geo-kelurahan">
                <Link to="/master/geography/geo-kelurahan">
                  <span>Kelurahan</span>
                </Link>
              </Item>
            </SubMenu>
          </MenuItemGroup>
        )}

        {permit.checkPermitLinkPrefix("/master/partner") && (
          <MenuItemGroup key="partner" className="gx-menu-group">
            <SubMenu
              key="partner"
              popupClassName={navStyleSubMenuClass}
              className={linkClassMatch([null],["/master/partner"], "subMenu")}
              title={
                <span>
                  <i className="icon icon-team" />
                  <span>Partner</span>
                </span>
              }>
              <Item
                key="master/partner/partner-file-list"
                className={linkClassMatch([null],["/partner-file-message", "/partner-file-upload"], "item")}
              >
                <Link to="/master/partner/partner-file-list">
                  <span>File Partner</span>
                </Link>
              </Item>

              <Item 
                key="master/partner/partner"
                className={linkClassMatch([null],["/embed-rule"], "item")}
              >
                <Link to="/master/partner/partner">
                  <span>Partner</span>
                </Link>
              </Item>

              <Item key="master/partner/partner-page">
                <Link to="/master/partner/partner-page">
                  <span>Custom Page</span>
                </Link>
              </Item>

              <Item key="master/partner/partner-input">
                <Link to="/master/partner/partner-input">
                  <span>Custom Input</span>
                </Link>
              </Item>

              <Item key="master/partner/partner-choice">
                <Link to="/master/partner/partner-choice">
                  <span>Custom Choice</span>
                </Link>
              </Item>

              <Item key="master/partner/partner-cost">
                <Link to="/master/partner/partner-cost">
                  <span>Upping</span>
                </Link>
              </Item>

              <Item key="master/partner/partner-tenure-cost">
                <Link to="/master/partner/partner-tenure-cost">
                  <span>Alt Eff Rate</span>
                </Link>
              </Item>
            </SubMenu>
          </MenuItemGroup>
        )}

        {permit.checkPermitLinkPrefix("/master/occupation") && (
          <Item key="master/other/occupation">
            <Link to="/master/other/occupation">
              <i className="icon icon-data-entry" />
              <span>Pekerjaan</span>
            </Link>
          </Item>
        )}

        {permit.checkPermitLinkPrefix("/master/pricing") && (
          <MenuItemGroup key="calculator" className="gx-menu-group">
            <SubMenu
              key="calculator"
              popupClassName={navStyleSubMenuClass}
              title={
                <span>
                  <i className="icon icon-apps" />
                  <span>Kalkulator</span>
                </span>
              }>
              <Item key="calculator/number-based">
                <Link to="/calculator/number-based">
                  <span>Basis Angka</span>
                </Link>
              </Item>
              <Item key="calculator/car-area">
                <Link to="/calculator/car-area">
                  <span>Kendaraan-Area</span>
                </Link>
              </Item>
            </SubMenu>
          </MenuItemGroup>
        )}
      </Menu>
    </CustomScrollbars>
  );
};

export default SidebarContentMain;
